import { Card, CardBody, CardImg, CardLink, CardText,CardHeader, CardImgOverlay } from "react-bootstrap";
import globe from '../images/icons/globe.webp';
import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone,faEnvelope,faLocationPin,faGlobe,faFax} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import img1 from '../images/flags/Flag_of_the_Netherlands.svg.png';
import img2 from '../images/flags/Flag-United-States-of-America.webp';
import img3 from '../images/flags/Flag-Malaysia.webp';
import img4 from '../images/flags/UAE.png';
import img5 from '../images/flags/China.png';
import img6 from '../images/flags/Flag_of_Turkey.svg.png';
import img7 from '../images/flags/Flag-Mexico.webp';
import globeimg from '../images/Officesimg.png';
import Header from "./Header";

const flags =[
    {id:1,src:img1,name:'The Netherlands'},
    {id:2,src:img2,name:'USA'},
    {id:3,src:img3,name:'Malaysia'},
    {id:4,src:img4,name:'UAE'},
    {id:5,src:img5,name:'China'},
    {id:6,src:img6,name:'Turkey'},
    {id:7,src:img7,name:'Mexico'},


]


library.add(faPhone,faEnvelope,faLocationPin,faGlobe,faFax);


function Offices(){
    const divStyle = {
        backgroundImage: `url(${globeimg})`,
       
       
        
      };

    return(
    <div className="Offices min-h-screen  md:h-screen w-screen bg-cover bg-center bg-no-repeat"  >
        <Header/>
        
        {/* <div style={divStyle} className="h-[100vh] md:h-[100vh] bg-cover bg-center   flex-auto items-end"> */}
      
        <div className="grid grid-cols ">
            <Card className="rounded-none top-20 p-4 bg-transparent border-0 shadow-none" >
            <CardHeader className="text-center p-0 "><h2 className=" bg-gray-900/60 text-white p-4 text-center">World Wide Offices</h2></CardHeader>
            
            <CardBody className="bg-gray-900/60 text-center items-center content-center  place-content-center ">
            <CardText >
                <div className="grid grid-cols-4  md:grid-cols-7 gap-2 place-content-center text-sm md:text-base ">
                    {flags.map((data,idx)=>(
                        <Card key={idx} className=" flex-grow-0  items-center p-2">
                        <CardImg src={data.src} style={{width:'45px',height:'40px'}}/>
                        <CardText>{data.name}</CardText>
                        </Card>
                    ))}
                    
                </div>
            </CardText>
            </CardBody>
            
            </Card>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-2 mt-16 pl-5 pr-5 ">
                <Card className="bg-transparent border-0 shadow-none">
                    <CardHeader className="text-center p-0 "><h3 className="bg-gray-900/60 text-white  text-center text-lg md:text-2xl font-bold pb-5 pt-4">MIL INDIA INSPECTION SERVICES PVT LTD</h3></CardHeader>

                    <CardBody  className=" bg-gray-900/60 text-white ">
                    <CardText className=" text-left p-2 text-base"> 
                    <FontAwesomeIcon icon="location-pin" className="pr-2" />     
                    C-606, Neelkanth Business Park,<br/>
                    Vidyavihar, West, Mumbai -400086 <br/>
                    <FontAwesomeIcon icon="phone" className="pr-2" />
                    8105445279  <br/>
                    <FontAwesomeIcon icon="envelope" className="pr-2" />
                    operations@mil-india.net
                    </CardText>
                    </CardBody>
                </Card>
                <Card className="bg-transparent border-0 shadow-none">
                <CardHeader className="text-center p-0 "><h3 className="bg-gray-900/60 text-white  text-center text-lg md:text-2xl font-bold pb-4 pt-3">MARINE INSPECTION AND LOGISTICS INTERNATIONAL ROTTERDAM BV</h3></CardHeader>

                <CardBody  className=" bg-gray-900/60 text-white">
                    <CardText className="text-left p-2 text-base"> 
                    <FontAwesomeIcon icon="location-pin" className="pr-2" />    
                    Reedijk 7U
                    3274 KE Heinenoord,
                    The Netherlands<br/>
                    <FontAwesomeIcon icon="globe"className="pr-2" /> <CardLink as='a' href="https:/www.mil-international.nl" target="_blank">www.mil-international.nl</CardLink>
                    <br/>
                    <FontAwesomeIcon icon="phone" className="pr-2" />
                    +31 (0)10 246 09 55 
                    <br/>
                    <FontAwesomeIcon icon="fax" className="pr-2" />
                    +31 (0)10 246 92 75
                    <br/>
                    <FontAwesomeIcon icon="envelope" className="pr-2"/>
                    operations@mil-international.nl
                    </CardText>
                    </CardBody>
                </Card>
                <Card className="bg-transparent border-0 shadow-none">
                <CardHeader className="text-center p-0 "><h3 className="bg-gray-900/60 text-white  text-center text-lg md:text-2xl font-bold pb-5 pt-4">MARINE INSPECTION AND LOGISTICS (FAR EAST) SDN. BHD.</h3></CardHeader>
                <CardBody  className=" bg-gray-900/60 text-white">
                    <CardText className="text-left p-2 text-base"> 
                    <FontAwesomeIcon icon="location-pin" className="pr-2" />    
                    Malaysia Offices : KLANG / PASIR GUDANG / PENANG<br/>
                    <FontAwesomeIcon icon="globe"className="pr-2" /> <CardLink as='a' href="http://mil-fe.com" target="_blank">http://mil-fe.com</CardLink>
                    <br/>
                    <FontAwesomeIcon icon="phone" className="pr-2" />
                    +603 3324 1218 
                    <br/>
                    <FontAwesomeIcon icon="fax" className="pr-2" />
                    +603 3324 5218
                    <br/>
                    <FontAwesomeIcon icon="envelope" className="pr-2"/>
                    operations@mil-fe.com
                    </CardText>
                    </CardBody>
              
                </Card>



            </div>
            </div>
        
            {/* </div>
             */}
            </div>
        
    )
}

export default Offices; 