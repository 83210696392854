import React from 'react';
import Header from './Header';
import FullScreenImage from './FullScreenImage';
import Footer from './Footer';
import Details from './Details';


import img1 from '../images/DRAFT ,BUNKER _ ON-HIRE OFF-HIRE CONDITION SURVEY/1.webp';
import img2 from '../images/DRAFT ,BUNKER _ ON-HIRE OFF-HIRE CONDITION SURVEY/2.webp';
import img3 from '../images/DRAFT ,BUNKER _ ON-HIRE OFF-HIRE CONDITION SURVEY/3.webp';
import img4 from '../images/DRAFT ,BUNKER _ ON-HIRE OFF-HIRE CONDITION SURVEY/4.webp';
import Icons from './Icons';


const slider_images =[
    {id:1,src:img1,alt:'Image 1'},
    {id:2,src:img2,alt:'Image 2'},
    {id:3,src:img3,alt:'Image 3'},
    {id:4,src:img4,alt:'Image 4'}
 

  ]

const detail_info =[
  {id:1,
   title:'DRAFT,BUNKER SURVEY & ON-HIRE / OFF-HIRE CONDITION',
   detail:`Our team of professional marine surveyors can carry out Draft, Ullage surveys on vessels to ascertain quantities loaded, discharged against Bills of lading quantity.

Bunker surveys to assess quantity on board as per client requirement and customs purposes.

Surveys can be carried out on behalf of owners & charterers or joint interest.` }
]  
function DraftBunker() {
  return (
    <div className="DraftBunker">
      <Header/>
      <FullScreenImage data={slider_images}/>
      <Details data={detail_info}/>
      <Icons/>
    </div>
  );
}

export default DraftBunker;