import React from 'react';
import Header from './Header';
import FullScreenImage from './FullScreenImage';
import Footer from './Footer';

import img1 from '../images/index/A.webp';
import img2 from '../images/index/B.webp';
import img3 from '../images/index/C.webp';
import img4 from '../images/index/D.webp';
import img5 from '../images/index/E.webp';
import img6 from '../images/index/F.webp';
import Details from './Details';
import Icons from './Icons';

const slider_images =[
    {id:1,src:img1,alt:'Image 1'},
    {id:2,src:img2,alt:'Image 2'},
    {id:3,src:img3,alt:'Image 3'},
    {id:4,src:img4,alt:'Image 4'},
    {id:5,src:img5,alt:'Image 5'},
    {id:6,src:img6,alt:'Image 6'}

  ]

const detail_info =[
  {id:1,
   title:'MIL INDIA INSPECTION SERVICES PVT.LTD',
   detail:'MIL India Inspection Services Pvt. Ltd. is an independent inspection company located in India and is a subsidiary of Marine Inspection and Logistics International Rotterdam BV. The company is a specialist in commodity inspections, marine surveying & consultancy services. We can handle your inspection needs anywhere in India and work to internationally-recognized standards. In other regions we co-operate closely with our global network of offices in Europe, Middle & Far East, China, Turkey, USA etc.'}
]  
function Home() {
  return (
    <div className="Home">
      <Header/>
      <FullScreenImage data={slider_images}/>
      <Details data={detail_info}/>
      <Icons/>
    </div>
  );
}

export default Home;