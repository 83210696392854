// Icons.js

import { faWhatsapp } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"



export default function Icons() {
    return (
        <div className="fixed right-0 top-1/2 z-50 
                        bg-transparent flex flex-col space-y-3">
            <a href="https://wa.me/9769851157" 
                className="flex  px-2
                           py-1.5 text-md font-medium no-underline text-center content-center place-content-center
                           uppercase leading-normal text-[#075E54]
                           rounded-l-xl hover:text-white hover:font-bold"
                style={{ backgroundColor: "#25d366" }} 
                >
                <FontAwesomeIcon  icon={faWhatsapp} size="xl" beat/>
                <span className="pl-2 hidden md:block">Whatsapp</span>
            </a>
           
        </div>
    )
}