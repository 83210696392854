import React from 'react';
import Header from './Header';
import FullScreenImage from './FullScreenImage';
import Footer from './Footer';

import img1 from '../images/AGRICULTURAL PRODUCTS _ FERTILIZERS/1.webp';
import img2 from '../images/AGRICULTURAL PRODUCTS _ FERTILIZERS/2.webp';
import Details from './Details';
import Icons from './Icons';



const slider_images =[
    {id:1,src:img1,alt:'Image 1'},
    {id:2,src:img2,alt:'Image 2'},
  ]

const detail_info =[
  {id:1,
    title:'AGRICULTURAL PRODUCTS & FERTILIZERS',
    detail:'Our specialized services include visual inspection, sampling, loading compartment, container inspection and supervision during loading/discharge operations and analytical certification services covering the full spectrum of test for various products such as grain, oilseeds, food, animal feed, vegetables and fertilizers.'}
] 
function AgriculturalProducts() {
  return (
    <div className="AgriculturalProducts">
      <Header/>
      <FullScreenImage data={slider_images}/>
      <Details data={detail_info}/>
      <Icons/>
    </div>
  );
}

export default AgriculturalProducts;