import React from "react";
import "../css/Footer.css";

import img1 from '../images/footer/BIR.png';
import img2 from '../images/footer/MRAI.png';
import img3 from '../images/footer/MRF.png';


function Footer() {
  return (
    <footer className="footer flex justify-center bg-black/90 text-slate-50 text-xs md:text-sm">
      <span>Our parent company is a Member of:</span>
            <img class="footer-img pl-3" src={img1} alt=""/>
            <img class="footer-img pl-3" src={img2} alt=""/>
            <img class="footer-img pl-3" src={img3} alt=""/> 
    </footer>
  );
}

export default Footer;