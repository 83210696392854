// Card.js

import { library } from "@fortawesome/fontawesome-svg-core";
import { faPhone,faEnvelope,faLocationPin} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Feedback from "./Feedback";
import ContactMap from "./ContactMap";
import "../css/CardComponent.css";

library.add(faPhone,faEnvelope,faLocationPin);

export default function CardComponent() {
  return (
    
        <>
            {/* <div className="max-w-md mx-auto bg-white mt-20 flex flex-col">
            <ContactMap  className='h-full w-full'/>
            <Card>
              <h5
                
              >
                Reach Us At
              </h5>
              <ul>
                <li className="mt-2">
                  <span>
                    <FontAwesomeIcon icon="phone" />{" "}{" "}
                  </span>
                  +91-8105445279
                </li>
                <li className="mt-2">
                  <span>
                  <FontAwesomeIcon icon="envelope" />{" "}{" "}
                  </span>
                  <span>operations@mil-india.net</span>
                </li>
                <li className="mt-2">
                  <span>
                  <FontAwesomeIcon icon="location-pin" />{" "}{" "}
                  </span>
                  C-606, NEELKANTH BUSINESS PARK,
                  <span className="pl-4">VIDYAVIHAR, WEST, MUMBAI -400086</span>
                </li>
              </ul>
              </Card>
              <Feedback />
            </div>
             */}
    <div className="bg-gray-100 main_container min-h-screen  md:h-screen w-screen bg-cover bg-center bg-no-repeat text-white py-32 md:py-32 px-10">
      
        {/* Wrapper */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-5 ">
          {/* Map Section */}
          <div className="md:col-span-2 bg-gray-900/70 p-6 rounded-lg shadow-md ">
          <div className="w-full md:1/2 ">
              <h2 className="text-lg md:text-2xl font-bold text-white mb-4 ">Reach Us At</h2>         
                    <ul className="text-base md:text-lg ">
                      <li className="mt-2">
                        <span className="pr-4">
                          <FontAwesomeIcon icon="phone" bounce/>
                        </span>
                        +91-8105445279
                      </li>
                      <li className="mt-2">
                        <span className="pr-4">
                        <FontAwesomeIcon icon="envelope" bounce/>
                        </span>
                        <span>operations@mil-india.net</span>
                      </li>
                      <li className="mt-2">
                        <span className="pr-4">
                        <FontAwesomeIcon icon="location-pin" bounce/>
                        </span>
                        C-606, NEELKANTH BUSINESS PARK,
                        <span className="pl-2">VIDYAVIHAR, WEST, MUMBAI - 400086</span>
                      </li>
                    </ul>
                    </div>
          <div className="w-full h-48  md:1/2 mb-12">
            <h2 className="text-lg md:text-2xl font-bold text-white mb-4">Our Location</h2>
              <ContactMap/>
              </div>
              
          </div>

          

          {/* Feedback Form Section */}
          <div className="bg-gray-900/70 p-6 rounded-lg shadow-md ">
           <Feedback/>
          </div>
        </div>
      </div>
      
   </>
  );
}
