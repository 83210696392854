import React from 'react';
import Header from './Header';
import FullScreenImage from './FullScreenImage';
import Footer from './Footer';
import Details from './Details';

import img1 from '../images/METALS, MINERALS, ORES & ALLOYS/1.webp';
import img2 from '../images/METALS, MINERALS, ORES & ALLOYS/2.webp';
import img3 from '../images/METALS, MINERALS, ORES & ALLOYS/3.webp';
import img4 from '../images/METALS, MINERALS, ORES & ALLOYS/4.webp';
import img5 from '../images/METALS, MINERALS, ORES & ALLOYS/5.webp';
import img6 from '../images/METALS, MINERALS, ORES & ALLOYS/6.webp';
import img7 from '../images/METALS, MINERALS, ORES & ALLOYS/7.webp';
import Icons from './Icons';


const slider_images =[
    {id:1,src:img1,alt:'Image 1'},
    {id:2,src:img2,alt:'Image 2'},
    {id:3,src:img3,alt:'Image 3'},
    {id:4,src:img4,alt:'Image 4'},
    {id:5,src:img5,alt:'Image 5'},
    {id:6,src:img6,alt:'Image 6'},
    {id:7,src:img7,alt:'Image 7'}
  ]
  const detail_info =[
    {id:1,
    title:'METALS, MINERALS, ORES & ALLOYS',
    detail:'The company is specialized in inspection of various commodities such as: Ferrous and Non Ferrous Steel Scrap, Metals Minerals, Ores & Ferro Alloys. The value of commodities is best determined by our experienced team by means of visual- quantity inspection, sampling and analysis which is backed by world class laboratories around the globe. '}
  ]  
function Minerals() {
  return (
    <div className="Minerals">
      <Header/>
      <FullScreenImage data={slider_images}/>
      <Details data={detail_info}/>
      <Icons/>
    </div>
  );
}

export default Minerals;