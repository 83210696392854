import Icons from "./Icons";
import Header from "./Header";
import Footer from "./Footer";
import CardComponent from "./CardComponent";

function ContactUs(){
    return (
      <>
        <Header/>
        <div className="h-[100vh] md:h-[100vh] bg-cover bg-center flex-auto items-end">
        <CardComponent/>
        <Icons/>
        </div>
        <Footer/>
    </>
    );
};

export default ContactUs;
