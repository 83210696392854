import React from 'react';
import Header from './Header';
import FullScreenImage from './FullScreenImage';
import Footer from './Footer';
import Details from './Details';

import img1 from '../images/PRE-LOADING, DISCHARGE _ OUTTURN SURVEYS/1.jpg';
import img2 from '../images/PRE-LOADING, DISCHARGE _ OUTTURN SURVEYS/2.jpg';
import img3 from '../images/PRE-LOADING, DISCHARGE _ OUTTURN SURVEYS/3.jpg';
import Icons from './Icons';



const slider_images =[
    {id:1,src:img1,alt:'Image 1'},
    {id:2,src:img2,alt:'Image 2'},
    {id:3,src:img3,alt:'Image 3'}
  ]

  const detail_info =[
    {id:1,
    title:'PRE-LOADING, DISCHARGE & OUTTURN SURVEYS',
    detail:'Our team has extensive experience in all cargo related matters. We can survey the condition of your cargo prior loading/discharge, supervise & advice on loading/unloading of the cargo and provide you with a detailed loading/outturn condition report.'}
  ]  
function Preloading() {
  return (
    <div className="Preloading">
      <Header/>
      <FullScreenImage data={slider_images}/>
       <Details data={detail_info}/>
      <Icons/>
    </div>
  );
}

export default Preloading;