import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";


delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});

const ContactMap = () => {
    const position = [19.079561, 72.896377]; // Example coordinates

    return (
        <div className="w-full h-full">
            <MapContainer center={position} zoom={18} className="h-full w-full z-0">
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    
                />
                <Marker position={position}>
                    <Popup>Your location</Popup>
                </Marker>
            </MapContainer>
        </div>
    );
};

export default ContactMap;
