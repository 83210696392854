import React from 'react';
import Header from './Header';
import FullScreenImage from './FullScreenImage';
import Footer from './Footer';

import img1 from '../images/CONTAINER SURVEYS – INSPECTION, STUFFING _ DE-STUFFING, CARGO SECURING SURVEYS/1.webp';
import img2 from '../images/CONTAINER SURVEYS – INSPECTION, STUFFING _ DE-STUFFING, CARGO SECURING SURVEYS/2.webp';
import img3 from '../images/CONTAINER SURVEYS – INSPECTION, STUFFING _ DE-STUFFING, CARGO SECURING SURVEYS/3.webp';
import img4 from '../images/CONTAINER SURVEYS – INSPECTION, STUFFING _ DE-STUFFING, CARGO SECURING SURVEYS/4.webp';
import Details from './Details';
import Icons from './Icons';


const slider_images =[
    {id:1,src:img1,alt:'Image 1'},
    {id:2,src:img2,alt:'Image 2'},
    {id:3,src:img3,alt:'Image 3'},
    {id:4,src:img4,alt:'Image 4'}
  ]

const detail_info =[
  {id:1,
  title:'CONTAINER SURVEYS – INSPECTION, STUFFING & DE-STUFFING, CARGO SECURING SURVEYS',
  detail:'We assist the shippers/receivers with regards to the container condition, cargo stuffing-destuffing, nature of stow and stability, securing- lashing arrangement in various types of containers and provide a detailed survey report.'}
]  

function ContainerSurveys() {
  return (
    <div className="Home">
      <Header/>
      <FullScreenImage data={slider_images}/>
      <Details data={detail_info} />
      <Icons/>
    </div>
  );
}

export default ContainerSurveys;