import React from 'react';
import Header from './Header';
import FullScreenImage from './FullScreenImage';
import Footer from './Footer';

import img1 from '../images/INCIDENT INJURY INVESTIGATION SURVEY/1.webp';
import img2 from '../images/INCIDENT INJURY INVESTIGATION SURVEY/2.webp';
import img3 from '../images/INCIDENT INJURY INVESTIGATION SURVEY/3.webp';
import img4 from '../images/INCIDENT INJURY INVESTIGATION SURVEY/4.webp';
import img5 from '../images/INCIDENT INJURY INVESTIGATION SURVEY/5.webp';
import img6 from '../images/INCIDENT INJURY INVESTIGATION SURVEY/6.webp';
import img7 from '../images/INCIDENT INJURY INVESTIGATION SURVEY/7.webp';
import Details from './Details';
import Icons from './Icons';


const slider_images =[
    {id:1,src:img1,alt:'Image 1'},
    {id:2,src:img2,alt:'Image 2'},
    {id:3,src:img3,alt:'Image 3'},
    {id:4,src:img4,alt:'Image 4'},
    {id:5,src:img5,alt:'Image 5'},
    {id:6,src:img6,alt:'Image 6'},
    {id:7,src:img7,alt:'Image 7'}
  ]

const detail_info =[
{id:1,
title:'INCIDENT /INJURY INVESTIGATION SURVEY',
detail:'During vessel operations unfortunately incidents / injuries can occur and the consequences may be significant to all parties. In a professional and sensitive manner our surveyors will comprehensively carry out investigation gathering evidence along with the recording of facts and circumstances leading up to the reported incident & provide an independent, unbiased, confidential detailed report.'}
]
function IncidentInjury() {
  return (
    <div className="Home">
      <Header/>
      <FullScreenImage data={slider_images}/>
      <Details data={detail_info}/>
      <Icons/>
    </div>
  );
}

export default IncidentInjury;