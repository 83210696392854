import './App.css';
import { Route,Routes} from 'react-router-dom';
import Home from './components/Home';
import Minerals from './components/Minerals';
import PageNotFound from './components/PageNotFound';
import Services from './components/Services';
import Preloading from './components/Pre-loading';
import PIClub from './components/P&IClub';
import ProjectCargo from './components/ProjectCargo';
import AgriculturalProducts from './components/AgriculturalProducts';
import LiquidBulkCargo from './components/LiquidBulkCargo';
import ContainerSurveys from './components/ContainerSurveys';
import Radioactivity from './components/Radioactivity';
import IncidentInjury from './components/Incident-Injury';
import DraftBunker from './components/DraftBunker';
import ContactUs from './components/ContactUs';
import Offices from './components/Offices';

function App() {
  return (
    <div className="App">
      <Routes>
        {/* Main route */}
        <Route path="/" element={<Home />} exact={true} />

        {/* Services route with nested routes */}
        <Route path="/services" element={<Services />}>
          <Route path="minerals" element={<Minerals />} />
          <Route path="pre-loading" element={<Preloading />} />
          <Route path="pi_club" element={<PIClub />} />
          <Route path="draft_bunker" element={<DraftBunker />} />
          <Route path="project_cargo" element={<ProjectCargo/>} />
          <Route path="agricultural_products" element={<AgriculturalProducts/>} />
          <Route path="liquid_bulk_cargo" element={<LiquidBulkCargo/>} />
          <Route path="container_surveys" element={<ContainerSurveys/>} />
          <Route path="radioactivity" element={<Radioactivity/>} />
          <Route path="incident_injury" element={<IncidentInjury/>} />




          {/* Add other nested services routes here */}
        </Route>

        {/* Office route */}

        <Route path="/offices" element={<Offices />} />

        {/* Contact route */}
        <Route path="/contact" element={<ContactUs />} />

        {/* Catch-all route */}
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}


export default App;
